<template>
  <div class="audit">
    <dir>
      <Form ref="form" />
    </dir>
    <div class="bottom-but">
      <el-button
        round
        type="primary"
        size="mini"
        @click="onClose()"
        style="background:none;border-color:#D5D6DF;color:#666666;margin-left:270px"
        >取消</el-button
      >

      <el-button
        round
        @click="onSubmit()"
        type="primary"
        style="background:#2370EB;border-color:#2370EB;color:#fff;"
        size="mini"
        >确定</el-button
      >
    </div>
  </div>
</template>
<script>
import { contractAudit } from "@/api/user/ae/contract.js";
import Form from "./form.vue";
export default {
  components: {
    Form,
  },
  data() {
    return {
      activeName: "1",
      id: "",
    };
  },
  methods: {
    getData(row) {
      this.id = row.id;
      let that = this;
      setTimeout(() => {
        that.$refs.form.getRelation(row);
      }, 50);
    },
    handleClick() {},
    onSubmit() {
      let data = {
        param: {
          id: this.id,
          auditRemark: this.$refs.form.auditRemark,
          status: this.$refs.form.radio,
        },
      };

      contractAudit(data).then((res) => {
        if (res.code == 200) {
          this.$message.success("审核成功");
        }
        this.onClose();
      });
    },
    onClose() {
      this.$refs.form.empty();
      this.$emit("close");
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
  /* WebKit browsers 适配谷歌 */
  font-size: 12px !important;
}
.audit {
  height: 100%;
  display: flex;
  .bottom-but {
    width: 100%;
    line-height: 48px;
    background: #fff;
    border-top: 1px solid #dee0e3;
    z-index: 1000;
    bottom: 0;
    position: fixed;
    button {
      width: 90px;
      z-index: 1000;
    }
  }
}
</style>
<style lang="scss">
.audit {
  .el-tabs.el-tabs--top {
    width: 100%;
    display: flex;
    height: 95%;
    flex-direction: column;
    .el-tabs__nav-scroll {
      padding-left: 20px;
    }
    .el-tabs__content {
      flex: 1;
      overflow: auto;
    }
  }
}
</style>
